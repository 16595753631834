@import '../../colors';

.edit-track-and-trace-page {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 40px;
    margin-bottom: 20px;

    .edit-track-and-trace {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        margin-left: 30px;
        border-radius: 20px;
        padding: 0px 20px 20px 20px;

        .edit-track-and-trace-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .edit-track-and-trace-title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .edit-track-and-trace-title-text {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 300;
                    margin-left: 20px;
                }

                .edit-track-and-trace-logo {
                    position: relative;
                    top: -20px;
                    display: flex;
                    background-color: $accent-color;
                    height: 60px;
                    width: 60px;
                    border-radius: 0 0 0 30px;
                    justify-content: center;
                    align-items: center; 
                    
                    .edit-track-and-trace-logo-svg {
                        width: 30px;
                        height: 30px;

                        path {
                          fill: white;
                        }
                    }
                }

                .landing-page-edit-title-text {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 300;
                    margin-left: 20px;
                }
            }

            .landing-page-edit-actions {

                .landing-page-edit-top-select {
                    margin-left: 10px;
                    font-size: 12px;
                    background-color: #E9E9E9;
                    padding: 5px 10px 5px 10px;
                    border-radius: 25px;
                    color:#717171;
                    cursor: pointer;
                }

                .landing-page-edit-top-select-selected {
                    background-color: $accent-color;
                    color:white;
                }
            }
        }

        .edit-track-and-trace-top-line {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 0px;
        }

        .edit-track-and-trace-name-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 20px;
            
            input {
                flex: 1;
                margin-left: 20px;
                margin-right: 10px;
                height: 15px;
                padding: 10px;
                border: 0.75px solid #DBDBDB;
                border-radius: 25px;
                background-color: #F5F5F5;
                color: #3C4858;
                outline: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
    
            label {
                color: #3C4858;
                font-weight: 500;
            }
    
            .edit-track-and-trace-text-input {
                width: 90px;
            }
        }

        .edit-track-and-trace-subtitle {
            font-weight: 500;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            margin-top: 20px;
            color: #3C4858;
        }
    
        .edit-track-and-trace-description {
            font-weight: 300;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            color: #3C4858;
        }

        .edit-track-and-trace-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;
            user-select: none;

            .edit-track-and-trace-fiel-name {
                height: 15px;
                padding: 10px 20px;
                border: 0.75px solid #DBDBDB;
                border-radius: 25px;
                background-color: #F5F5F5;
                color: #3C4858;
                outline: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 150px;
                font-weight: 300;
            }
    
            label {
                color: #3C4858;
                font-weight: 500;
                width: 100px;
            }

            .remove-icon {
                margin-left: 5px;
                cursor: pointer;
            }
        }

        .add-field {
            display: flex;
            flex-direction: row;
            margin: 20px 0px 0px 100px;
            background-color:  $accent-color;
            border-radius: 20px;
            padding: 10px 20px;
            color: white;
            align-items: center;
            width: 150px;
            user-select: none;
            outline: 0;
            cursor: pointer;

            label {
                margin-left: 10px;
                cursor: pointer;
            }


            .new-field-options {
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 230px;
                top: 940px;
                margin-top: 10px;
                width: 180px;
                border: 0.6px solid #898989;
                background-color: white;
                z-index: 2;
                outline: 0;
                box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
                user-select: none;

                label {
                    padding: 10px;
                    color: #3C4858;
                    margin-left: 0px;
                }

                label:hover {
                    background-color: grey;
                    color: white;
                }
            }
        }


        .file-selector {
            margin-top: 10px;
        }
    
        .edit-track-and-trace-menu-line {
            margin-top: 30px;
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #eeeeee;
            height: 1px;
        }

        .color-picker {
            margin-top: 10px;
        }
    
        .text-switch {
            margin-top: 10px;
        }

        button {
            min-width: 90px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border:none;
            padding: 10px 20px;
            outline: 0;
            margin: 20px;
            cursor: pointer;
        }

        .track-and-trace-settings {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 30px;
            user-select: none;

              .title {
                font-size: 20.5px;
                line-height: 18px;
                color: #000000;
              }

            .qr {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .left {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    button {
                        min-width: 90px;
                        border-radius: 25px;
                        color: white;
                        align-self: center;
                        background-color: $accent-color;
                        border:none;
                        padding: 10px 40px;
                        outline: 0;
                        margin: 20px;
                        cursor: pointer;
                    }
                }

                .right {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            hr {
                border: 0;
                clear:both;
                display:block;
                width: 98%;               
                background-color: #eeeeee;
                height: 1px;
                margin-top: 20px;
            }

            .nfc {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .nfc-label {
                    display: flex;
                    flex-direction: row;
                    border-radius: 25px;
                    background-color: #EFEFEF;
                    align-items: center;

                    .copy-btn {
                        background-color: #FFCC33;
                        color: white;
                        border-radius: 25px;
                        padding: 10px;
                        width: 150px;
                        text-align: center;
                        cursor: pointer;
                    }

                    .url {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0 10px;
                        width: 3f0vw;
                    }
                }
            }
        }
             
    }

    .edit-track-and-trace-preview {
        display: flex;
        align-items: center;
        flex: 1;
        display: unset;

        .edit-track-and-trace-preview-content {
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 90px;
            margin-left: 50px;

            .edit-track-and-trace-phone-preview {
                display: flex;
                
                img {
                    z-index: 3;
                    width: 300px;
                    height: 600px;
                    object-fit: contain;
                }

                .phone-preview-box {
                    position: absolute;
                    top: 5px;
                    left: 14px;
                    bottom: 15px;
                    width: 272px;
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url('../../../images/unaro-yellow-bg.svg');

                    .title-preview {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .icon {
                            margin-left: 5px;
                            width: 15px;
                        }
                    }

                    .edit-track-and-trace-box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 40px 30px;
                        height: 100%;
                        border-radius: 25px;
                        padding: 20px;
                        justify-content: space-between;

                        .edit-track-and-trace-top {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            img {
                                width: 150px;
                                height: 40px;
                                margin: 20px 0;
                            }

                            .connect-with-email {
                                font-family: Futura PT Bold;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 15px;
                                text-align: center;
                                color: #EA5454;                
                            }

                            .input-preview {
                                border-bottom: 0.6px solid #898989;
                                color: #666666;
                                width: 100%;
                                font-family: Futura PT Book;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 18px;
                                margin: 15px 0;
                            }
                        }

                        .edit-track-and-trace-bottom {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            

                            .edit-track-and-trace-register-btn {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                padding: 5px 15px;
                                border-radius: 30px;
                                width: 145px;
                                align-self: center;
                                margin: 2px;
                    
                                label {
                                    font-family: Futura PT Book;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 25px;
                                    color: #FFFFFF;
                                    margin-right: 10px;
                                }
                            }

                            .add-more {
                                .add-more-icon {
                                    width: 20px;
                                }

                                label {
                                    font-size: 15px;
                                    margin-left: 5px;
                                }
                            }

                            .privacy {
                                font-family: Futura PT Book;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 10px;
                                color: #666666;
                                margin-top: 5px;
                                align-self: center;
                            }
                        }
                    }
                    
                }
            }
        }
    }
}