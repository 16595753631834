@import '../colors';

.buttoned-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    label {
        color: #3C4858;
        font-weight: 500;
        width: 100px;
    }

    input {
        width: 200px;
        height: 40px;
        padding: 10px 15px 10px 15px;
        border: 0.75px solid #DBDBDB;
        border-radius: 25px;
        background-color: #F5F5F5;
        color: #3C4858;
        outline: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 15px;
    }

    button {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        outline: 0;
        font-size: 25px;
        padding: 5px 5px 5px 5px;
        margin-left: 10px;
        cursor: pointer;
    }
}