@import './colors';

.top-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    padding: 20px 40px 20px 20px;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    z-index: 4;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);

    .dropdown-container {

        .options {
            z-index: 4;
        }
    }

    .top-bar-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .exit-organistion {
            border-radius: 45px;
            border: 1px solid $accent-color;
            color: $accent-color;
            align-self: center;
            background-color: white;
            padding: 10px 25px;
            margin-right: 20px;
            outline: 0;
            user-select: none;
            cursor: pointer;

            label {
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
                font-family: Roboto;
            }
        }
    
        .venue-name {
            align-self: center;
            align-self: center;
            background: #F7F7F7;
            border-radius: 32px;
            padding: 12px 20px;
            outline: 0;
            margin-left: 20px;
            user-select: none;

            color: #5B5B5B;
            font-size: 14px;
            font-weight: 400;
            font-family: Roboto;
        }
    }

    .support-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 110px;
        user-select: none;

        label {
            font-family: Roboto Bold;
            margin-left: 10px;
            font-weight: 500;
            font-size: 14px;
            color: #3C4858;
        }
    }

    .edit-mode {
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        cursor: pointer;

        label {
            font-family: Roboto Bold;
            margin-right: 15px;
            font-weight: 500;
            font-size: 14px;
            color: #3C4858;
            cursor: pointer;
        }

        .switch-icon {
            height: 25px;
            width: 50px;
            cursor: pointer;
        }
    }

    .email-creator {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 23px;         
            font-weight: normal;
            font-size: 24px;
            display: flex;
            margin-left: 10px;
            margin-top: 10px;
            font-weight: 400;
          }
    }
}