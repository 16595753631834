.campaign-dashboard {
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex: 1;
    user-select: none;

    button {
        background: #FFCC33;
        border-radius: 20px;
        border: none;
        outline: 0;
        font-weight: 500;
        font-size: 12;
        color: #FFFFFF;
        padding: 10px;
        width: 180px;
        margin-left: 20px;
        cursor: pointer;
    }

    .create-btn {
        background: #E85558;
    }

    .cd-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .cd-title {
            font-size: 38px;
            color: #858688;
            align-self: center;
        }

        .cd-actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            .contact-dropdown-container {
                cursor: pointer;

                .dropdown {
                    min-width: 110px;
                    padding: 10px 20px;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #FF9933;
                    cursor: pointer;
                    align-items: center;
                    align-items: center;
                    position: relative;

                    .logo {
                        path {
                            fill: white;
                        }
                    }
    
                    label {
                        width: inherit;
                        color: white;
                        cursor: pointer;
                    }
                }
    
                .options {
                    min-width: 110px;
                    height: fit-content;
    
                    label {
                        width: inherit;
                        cursor: pointer;
                        color: black;
                    }
                }
            }
        }
    }

    .cd-stats {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 2;
        margin-top: 20px;
        
        .cd-sms-stats {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background: #FFFFFF;
            box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
            border-radius: 6.62564px;
            flex: 1;
            height: 100%;

            .cd-stat {
                display: flex;
                flex-direction: column;
                padding: 0px 30px;
                cursor: pointer;

                :first-child {
                    font-weight: 500;
                    font-size: 38px;
                    color: #FF9933;
                    cursor: pointer;
                }

                :nth-child(2) {
                    font-weight: 500;
                    font-size: 16px;
                    color: #373737;
                    cursor: pointer;
                }

                .failed {
                    color: #EA5454;
                    cursor: pointer;
                }

                .total {
                    color: #FFCC33;
                    cursor: pointer;
                }
            }
        }

        .cd-chart {
            display: flex;
            flex: 4;
            height: 100%;

            .chart-widget {
                margin: 0px;
                margin-left: 20px;

                .chart-widget-top {
                    .title {
                        border: none;
                        margin-bottom: 10px;
                        font-size: 25px;
                    }
                } 
            }
        }

    }

    .cd-campaigns {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        height: 300px;
       
        @media only screen and (max-height: 800px) {
            height: 200px;
        }

        @media only screen and (max-height: 750px) {
            height: 150px;
        }

        @media only screen and (max-height: 950px) {
            height: 230px;
        }

        .no-campaigns-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
            border-radius: 6.62564px;
            flex: 4;
            padding: 20px;

            .no-campaigns {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                :first-child {
                    font-weight: 400;
                    font-size: 25px;
                }
    
                :nth-child(2) {
                    font-weight: 300;
                    font-size: 18px;
                    margin-top: 10px;
                }
    
                button {
                    margin-top: 30px;
                    margin-left: 0px;
                }
            }
        }

          .campaigns-container {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
            border-radius: 6.62564px;
            flex: 4;
            padding: 20px;
            position: relative;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 15px;
                background: #EFEFEF;
                border-radius: 18px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 18px;
                background-color:#D9D9D9;
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }

            .campaigns {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: 100%;
    
                .cd-campaign {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 80px;
                    margin-left: 10px;
                    border-bottom: 1px solid #DEDEDE;
                    outline: none;
                    min-height: 70px;
                    cursor: pointer;
    
                    .campaign-info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 20px 0;
                        cursor: pointer;
    
                        .dot {
                            background-color: #EA5454;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 50%;
                            margin-right: 20px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
    
                        :nth-child(2) {
                            font-size: 23px;
                            color: #373737;
                            margin-left: 20px;
                            cursor: pointer;
                        }
    
                        :nth-child(3) {
                            font-size: 23px;
                            color: #EA5454;
                            margin-left: 5px;
                            cursor: pointer;
                        }
    
                        :nth-child(4) {
                            font-size: 23px;
                            color: #EA5454;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
    
                    .campaign-actions {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
    
                        .campaign-state {
                            width: 90px;
                            padding: 7px 0;
                            background: #E85558;
                            border-radius: 23.0417px;
                            font-weight: 300;
                            font-size: 14px;
                            text-align: center;
                            color: #FFFFFF;
                        }

                        .menu-icon {
                            margin-left: 50px;
                            cursor: pointer;
                        }

                    }
                }

                :last-child {
                    border-bottom: none;
                }
            }


            .campaign-menu {
                position: absolute;
                display: flex;
                flex-direction: column;
                background-color: white;
                z-index: 2;
                right: 40px;
                top: 50px;
                box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
                border-radius: 6.62564px;
                padding: 10px;
                width: 140px;
                outline: none;

                label {
                    font-weight: 300;
                    font-size: 12px;
                    color: #373737;
                    padding: 10px;
                    cursor: pointer;
                }

                hr {
                    border: 0;
                    clear:both;
                    display:block;
                    width: 98%;               
                    background-color: #BABABA;
                    height: 0.3px;
                    margin-top: 0px;
                }
            }
          }

        .total-spent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
            border-radius: 6.62564px;
            height: 100%;
            flex: 1;
            margin-left: 30px;
            position: relative;


            .total-spent-content {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;

                :first-child {
                    font-weight: 500;
                    font-size: 30px;
                    color: #EA5454;
                }

                :nth-child(2) {
                    font-weight: 500;
                    font-size: 17px;
                    color: #373737;
                    border-bottom: 0.28125px solid #BABABA;
                    padding: 5px 0;
                    margin-bottom: 5px;
                }

                :nth-child(3) {
                    font-weight: 300;
                    font-size: 11px;
                    margin-bottom: 2px;
                }

                :nth-child(4) {
                    font-weight: 300;
                    font-size: 11px;
                }
            }
        }
    }
}