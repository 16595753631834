@import './colors';

.analytics {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 20px;
    margin-right: 20px;   
    flex: 1;

    @media only screen and (max-height: 900px) {
        height: 450px;            
    }
}

.analytics-row {
    display: flex;
    flex-direction: row-reverse;
}
