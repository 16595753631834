.sms-campaigns-list-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    height: 100%;

    .sms-campaigns-list-widget-container { 
         display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .sms-campaigns-list-widget-icon {
            height: 20px;
            align-self: flex-end;
        }
    
        .sms-campaigns-list-widget-title {
            font-weight: 500;
            font-size: 12px;
            padding: 10px 0;
            color: #373737;
            border-bottom: 1px solid #EEEEEE;
        }
    
        .sms-campaigns-list-widget-content {
            display: flex;
            flex-direction: column;
            flex: 1;
    
            .header {
                display: grid;
                grid-template-columns: 1.5fr 1fr 1fr;
                padding: 20px 0;
                padding-right: 20px;

                label {
                    font-family: Roboto;
                    font-weight: 700;
                    font-size: 12px;
                }
            }

            .campaigns {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                padding-right: 20px;

                .campaign {
                    display: grid;
                    grid-template-columns: 1.5fr 1fr 1fr;
                    padding: 10px;

                    label {
                        color: #5B5B5B;
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: 300;
                    }

                    :first-child {
                        font-weight: 500;
                    }
                }

                .campaign-dark {
                    background-color: #F2F2F2;
                }
            }
            
        }
    }
}

.sms-campaigns-list-widget-edit * {
    cursor: grab;
}

.sms-campaigns-list-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}