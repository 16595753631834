@import './colors';

.map-container {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex: 2;

    @media only screen and (max-height: 900px) {
        height: 500px;            
    }
    .map-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
        margin-bottom: -30px;
        margin-left: 5px;

        .logo {
            position: relative;
            top: -25px;
            display: flex;
            background-color: #FECB45;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 

            img {
                height: 30px;
                width: 30px;
                align-self: center;
            }
        }
        
        .title {
            display: flex;
            flex-direction: row;                
            label {
                margin-left: 20px;
                font-size: 20px;
            }
        }

        .search {
            input {
                border-radius: 25px;
                width: 200px;
                padding: 10px;
                background: transparent;
                border: 0;
                outline: 0;
                background-color: #eeeeee;
            }
        }
    }
  
    .map {
        display: flex;
        flex: 1;
    }
}