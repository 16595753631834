.switch-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #E8E8E8;
    width: 50px;
    height: 28px;
    border-radius: 30px;
    cursor: pointer;

    .switch {
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 0 4px;
        cursor: pointer;
        transition: transform 0.5s;
    }
}

.active {
    background-color: #FFCC33;

    .switch {
        transform: translate(21px);
    }
}