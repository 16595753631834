@import './colors';

.stats-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 0px 20px;
    margin-left: 20px;
    margin-bottom: 50px;
    user-select: none;

    .statistics-card-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .logo {
            position: relative;
            top: -20px;
            display: flex;
            background-color: $accent-color;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 

            img {
                height: 25px;
                width: 25px;
                align-self: center;
            }
        }

        .card-numbers {
            display: flex;
            flex-direction: column;
            text-align: end;

            .card-title {
                color: #aaaaaa;
            }

            .card-number {
                margin-top: 5px;
                font-size: 20px;
            }
        }
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;       
        background-color: #eeeeee;
        height: 1px;
        margin-top: 10px;
      }

    .statistics-card-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
        }

        label {
            margin-left: 10px;
            color: #aaaaaa;
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
}

.stats-card-columns {
    margin-bottom: 20px;

    hr {
        margin-top: -12px;
    }
}

@media only screen and (max-width: 1200px) {
    .stats-card  {
        flex: 1;
        margin-right: 15px;

        .card-numbers {
            .card-title {
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .stats-card  {
        flex: 1;
        margin-right: 15px;

        .card-numbers {
            .card-title {
                width: auto;
            }
        }
    }
}

