@import '../colors';

.landing-page-item {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    background-color: white;
    padding: 10px 20px 0px 20px;
    margin-left: 50px;
    margin-bottom: 20px;
    margin-top: 30px;

    img {
        position: relative;
        background-color: grey;
        width: 120px;
        height: 120px;
        top: -30px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .landing-page-item-description {
        display: flex;
        flex:2;
        flex-direction: column;
        margin-left: 10px;

        label {
            font-size: 25px;
            color: #3C4858;
            margin-top: 20px;
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 20px;
        }
        
        .landing-page-item-actions {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-bottom: 20px;

            .action-btn {
                outline: 0;
                border: none;
                font-weight: bold;
                border-radius: 25px;
                margin-right: 20px;
                padding: 5px;
                width: 80px;
                outline: 0;
                color: white;
                background-color: $accent-color;
                cursor: pointer;
            }

            .action-btn-edit {
                background-color: #FECB45;
            }

            .action-btn:hover {
                color: #3C4858;
                background-color: #eeeeee;
            }
        }
    }
}