@import '../colors';

.landing-page-edit {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 40px;
    margin-bottom: 20px;

    .edit {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        margin-left: 30px;
        border-radius: 20px;
        padding: 0px 20px 20px 20px;

        .landing-page-edit-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .landing-page-edit-title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .landing-page-edit-title-text {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 300;
                    margin-left: 20px;
                }

                .landing-page-edit-logo {
                    position: relative;
                    top: -20px;
                    display: flex;
                    background-color: $accent-color;
                    height: 60px;
                    width: 60px;
                    border-radius: 0 0 0 30px;
                    justify-content: center;
                    align-items: center; 
                    
                    .landing-page-edit-logo-svg {
                        width: 30px;
                        height: 30px;

                        path {
                          fill: white;
                        }
                    }
                }
            }

            .landing-page-edit-actions {

                .landing-page-edit-top-select {
                    margin-left: 10px;
                    font-size: 12px;
                    background-color: #E9E9E9;
                    padding: 5px 10px 5px 10px;
                    border-radius: 25px;
                    color:#717171;
                    cursor: pointer;
                }

                .landing-page-edit-top-select-selected {
                    background-color: $accent-color;
                    color:white;
                }
            }
        }

        .edit-landing-page-top-line {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 0px;
        }

        .edit-landing-page-save-btn {
            min-width: 140px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border:none;
            padding: 10px;
            outline: 0;
            margin: 20px;
            cursor: pointer;
        }
    }

    .preview {
        display: flex;
        align-items: center;
        flex: 1;
        display: unset;

        .preview-content {
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 90px;
            margin-left: 50px;

            .preview-navigator {
                display: flex;
                margin-bottom: 30px;
                margin-top: 20px;

                .preview-bullet-navigator {
                    position: absolute;
                    left: 105px;

                    display: flex;
                    flex-direction: row;
                    align-self: center;
                }
            }

            .phone-preview {
                display: flex;
                
                img {
                    z-index: 3;
                    width: 300px;
                    height: 600px;
                }

                .phone-preview-box {
                    position: absolute;
                    top: 64px;
                    left: 14px;
                    bottom: 15px;
                    width: 272px;
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    
                }
            }
        }
    }
}