@import '../colors';

.edit-landing-page-design {
    display: flex;
    flex-direction: column;

    .landing-page-name-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        
        input {
            flex: 1;
            margin-left: 20px;
            margin-right: 10px;
            height: 15px;
            padding: 10px;
            border: 0.75px solid #DBDBDB;
            border-radius: 25px;
            background-color: #F5F5F5;
            color: #3C4858;
            outline: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        label {
            color: #3C4858;
            font-weight: 500;
        }

        .landing-page-text-input {
            width: 90px;
        }
    }

    .landing-page-edit-subtitle {
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin-top: 20px;
        color: #3C4858;
    }

    .landing-page-edit-description {
        font-weight: 300;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #3C4858;
    }

    .file-selector {
        margin-top: 10px;
    }

    .edit-landing-page-menu-line {
        margin-top: 30px;
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
    }

    .landing-page-edit-social-switch-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .switch {
            margin-right: 65px;
        }
    }

    .color-picker {
        margin-top: 10px;
    }

    .text-switch {
        margin-top: 10px;
    }

    .edit-landing-page-send {

        input {
            width: 160px;
            height: 15px;
            padding: 10px;
            border: 0.75px solid #DBDBDB;
            border-radius: 25px;
            background-color: #F5F5F5;
            color: #3C4858;
            outline: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        button {
            min-width: 90px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border:none;
            padding: 10px;
            outline: 0;
            margin: 20px;
        }
    }
}