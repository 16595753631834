.chart-widget {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .chart-widget-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }
        
        .chart-widget-icon {
            height: 20px;
            align-self: flex-end;
        }
    
        .chart-widget-top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            .title {
                font-family: Roboto;
                font-weight: 500;
                font-size: 12px;
                color: #373737;
                padding: 10px 0;
                margin-left: 10px;
                border-bottom: 1px solid #EEEEEE;
            }
    
            .selectors {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: flex-end;
                margin: 5px 0;
    
                .selector {
                    width: 50px;
                    background-color: #E9E9E9;
                    margin: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                    padding: 5px 0;
                    cursor: pointer;
    
                    label {
                        color: #717171;
                        font-weight: 300;
                        font-size: 10px;
                        cursor: pointer;
                    }
                }
    
                .selected-selector {
                    background-color: #E85558;
                    label {
                        color: white;
                    }
                }
            }
        }
     
    
        .chart {
            flex: 1;
        }
    
        .y-title {
            position: absolute;
            transform: rotate(-90deg);
            top: 50%;
            left: 2px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 300;
        }
    
        .x-title {
            position: absolute;
            bottom: 3px;
            left: 50%;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 300;
            margin-bottom: 20px;
        }
    }
    
    .custom-tooltip {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 6px;
        border-radius: 6px;
    
        label {
            font-size: 12px;
            color: #ffffff;
        }
    
        .tooltip-content {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            .square {
                width: 10px;
                height: 10px;
                background-color: #ffffff;
            }
    
            :nth-child(2) {
                margin-left: 5px;
            }
        }
    }

}

.chart-widget-edit * {
    cursor: grab;
}

.chart-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}