@import '../colors';

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .switch-icon {
        width: 25px;
        height: 25px;
    }

    .switch-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        height: 15px;
        border: 0.75px solid #DBDBDB;
        border-radius: 25px;
        background-color: #F5F5F5;
        margin-left: 10px;
        width: 60px;
        cursor: pointer;

        label {
            outline: 0;
            user-select: none;
            cursor: pointer;
        }

        label:focus {
            outline: 0;
        }

        .switch-buttons {
            display: flex;
            flex-direction: column;
            
            margin-right: 10px;

            .switch-button {
                width: 20px;
                height: 20px;
                margin: -8px;
                cursor: pointer;
            }
            
        }
    }
   

}