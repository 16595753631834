@import '../colors';

.percent-slider {
    display: flex;
    flex-direction: row;

    .percent-slider-title {
        color: #3C4858;
        font-weight: 500;
        width: 180px;
    }

    .percent-slider-value {
        margin-left: 20px;
    }

    .rc-slider {
        width: 100px;
        margin-left: 20px;
    }
}