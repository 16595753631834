.widget-selector {
    position: fixed;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(183.34deg, #FFFFFF 37.37%, #FAFBFB 57.37%);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
    height: 100%;
    width: 260px;
    z-index: 5;
    left: 0;
    top: 0;
    transition: width 1s;
    display: none;
    animation: display-none-transition 1s both; 

    .menu-icons {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-left: 60px;
        align-items: center;
        width: 100%;

        .menu-logo {
            display: flex;
            flex-direction: row;
            width: 150px;
            height: 30px;
            transition: width 1.2s linear;
            transform: translate(25px);
            transition: transform 1s;

            .logo-icon {
                width: 30px;
            }

            .logo {
                visibility:visible;
                opacity: 1;
                transition: visibility 1s, opacity 1s linear;
                transition-delay: 0.3s;
            }
        }
    }

    .search-widget {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #F5F5F5;
        border: 0.75px solid #DBDBDB;
        border-radius: 21.75px;
        margin-top: 40px;
        margin-bottom: 20px;
        width: 200px;
        padding: 5px 10px;
        transition: width 1s;

        .icon {
            line {
                stroke: #DBDBDB;
            }

            circle {
                stroke: #DBDBDB;
            }
        }

        input {
            border: none;
            outline: none;
            background: #F5F5F5;
            border-radius: 21.75px;
            margin-left: 5px;
            font-family: Roboto;
            font-weight: 300;
            font-size: 12px;
            color: #3C4858;
            width: 100%;
        }
    }

    ::-webkit-scrollbar { 
        display: none;
    }

    .products {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: calc(100% - 240px);
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .product:active {
            cursor: grabbing;
        }

        .product {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            cursor: grab;

            .product-name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                padding: 0 20px;

                .pn-left {
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;

                    img {
                        width: 25px;
                        height: 25px;
                        object-fit: contain;
                        align-self: baseline;
                        cursor: pointer;
                    }

                    label {
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 14px;
                        color: #848484;
                        margin-top: 7px;
                        margin-left: 15px;
                        cursor: pointer;
                        animation: display-none-transition 1s both;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .product-open-icon {
                    width: 10px;
                    height: 10px;
                    transform: rotate(-90deg);
                    transition: transform 0.5s;
                }
               
                .product-open-icon-open {
                    transform: rotate(0deg);
                }
            }

            .product-widgets {
                display: grid;
                grid-template-columns: 1fr 1fr;
                row-gap: 12px;
                column-gap: 12px;
                margin-top: 30px;
                padding: 0 20px;

                .widget {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
                    border-radius: 6px;
                    padding: 7px;

                    img {
                        width: 90px;
                        height: 70px;
                        object-fit: fill;
                        pointer-events: none;
                    }
                }

                .wide {
                    grid-column: 1/3;

                    img {
                        width: 200px;
                        object-fit: fill;
                    }
                }
              
                .tall {
                    grid-row: 1/3;
                    
                    img {
                        height: 140px;
                        object-fit: fill;
                    }
                }
            }
        }
    }

    button {
        background: #E85558;
        border-radius: 21.75px;
        border: none;
        width: 100px;
        height: 30px;
        font-weight: 300;
        font-size: 14px;
        font-family: Roboto;
        color: #FFFFFF;
        cursor: pointer;
        outline: none;
        transition: width 1s;
    }
}

.small-widget-selector {
    width: 90px;

    .menu-icons {
        .menu-logo {
            width: 30px;
            transform: translate(0px);

            .logo {
                opacity: 0;
                visibility: hidden;
                transition-delay: 0s;
                transition: visibility 0.3s, opacity 0.3s linear;
            }
        }
    }

    .search-widget {
        width: 50px;
    }

    .products {
        .product {
            .product-name {
                .pn-left {
                    label {
                        display: none;
                        animation: display-none-transition 1s both; 
                    }
                }           
            }
        }
    }

    button {
        width: 70px;
    }
}

.show-widget-selector {
    display: flex;
    animation: display-none-transition 1s both;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}