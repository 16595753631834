.consent-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    flex: 1;

    .consent-screen-top {
        display: flex;
        flex-direction: column;

        .consent-screen-title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 10px;
            background-color: red;

            label {
                color: white;
            }
        }

        .consent-screen-consents {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.03);
            
            .consent-screen-consent {
                flex: 1;
                text-align: center;
                color: rgb(113, 113, 113);
                padding: 15px;
                border-bottom: 1px solid rgba(0,0,0,.125);
            }

            .consent-screen-consent-selected {
                background-color: white;
                border-bottom: none;
                border-right: 1px solid rgba(0,0,0,.125);
            }

            label {
                font-size: 12px;
            }
        }

        .consent-screen-data {
            display: flex;
            flex-direction: column;
            margin: 20px 25px 20px 20px;
            padding: 10px;
            border: 1px solid rgba(0,0,0,.125);

            .consent-screen-data-title {
                font-size: 14px;
                font-weight: bold;
            }  

            .consent-screen-data-text {
                font-size: 12px;
                margin-top: 5px;
            }
        }

        .consent-screen-tick-boxes {
            display: flex;
            flex-direction: column;
            margin: 20px 25px 20px 20px;
            padding: 10px;
            border: 1px solid rgba(0,0,0,.125);

            .consent-screen-tick-box {
                display: flex;
                flex-direction: row;

                label {
                    font-size: 12px;
                    margin-left: 2px;
                }
                
                a {
                    color: #717171;
                    text-decoration: none;
                }
            }

            .consent-screen-tick-box-terms {
                margin-top: 5px;
            }
        }
    }

    .consent-screen-bottom {
        display: flex;
        flex-direction: row;
        padding: 10px;
        align-items: center;
        justify-content: center;

        label {
            color: white;
            font-size: 12px;
        }
    }

}