@import '../../colors.scss';

.email-registration-screen {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .email-registration-screen-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        margin: 40px 20px 20px 20px;
        height: 100%;
        border-radius: 25px;
        justify-content: space-between;

        .registration-top {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-around;

            .registration-logo {
                width: 170px !important;
                height: 50px !important;
                align-self: center;
                object-fit: contain;
            }

            .registration-title {
                margin: 15px 0;
                font-weight: bold;
                font-size: 18px;
                line-height: 18px;
                color: $accent-color;
                font-family: Futura PT Bold;
                align-self: center;
            }
            
            label {
                font-size: 12px;
            }

            input {
                border: 0;
                outline: 0;
                background: transparent;
                border-bottom: 0.6px solid #898989;
                height: 10px;
                padding: 5px;
                font-size: 15px;
                margin: 5px 0;
                font-family: Futura PT Book;
                border-radius: 0;
                margin-bottom: 10px;
            }

            .preview-dropdown {
                margin-top: 3px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 3px;
                border: 1px solid #dee2e6;
                border-radius: 3px;
                margin-bottom: 3px;
                outline: 0;

                .preview-dropdown-logo {
                    width: 10px;
                    margin-left: 5px;
                }

                label {
                    font-size: 12px;
                }
            }

            .birthday {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0.6px solid #898989;
                height: 10px;
                padding: 5px;
                margin: 5px 0;
                margin-bottom: 20px;

                label {
                    font-size: 15px;
                    font-family: Futura PT Book;
                    border-radius: 0;
                    color: #666666;
                }
            }

            .registration-dropdown {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0.6px solid #898989;
                height: 10px;
                padding: 5px;
                margin-bottom: 20px;

                label {
                    font-size: 15px;
                    margin: 5px 0;
                    font-family: Futura PT Book;
                    border-radius: 0;
                    color: #666666;
                    margin-right: 5px;
                }

                .preview-dropdown-logo {
                    margin-left: 5px;
                }
            }
    
        }
       
        .registration-bottom {
            display: flex;
            flex-direction: column;
            
            .register-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background-color: $login-background-color;
                padding: 10px 20px;
                border-radius: 30px;
                width: fit-content;
                align-self: center;
                margin: 10px;
                width: 100px;
                height: 20px;

                label {
                    font-family: Futura PT Book;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    color: #FFFFFF;
                    margin-right: 10px;
                }

                .arrow {
                    height: 15px;
                }
            }
            
            .privacy {
                font-family: Futura PT Book;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: #666666;
                margin-top: 5px;
                align-self: center;
            }
        }
    }
}