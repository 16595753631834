@import './colors';

.tops {
    display: flex;
    flex-direction: row;   
    flex: 1;

    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

    .tops-stats {
        display: flex;
        flex-direction: row;
        flex: 2.5;
    }

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex: 2;
    }

    .cards-2-columns {
        grid-template-columns: 1fr;
        flex: 1;
    }

}  

@media only screen and (max-width: 1000px) {
    .tops {
        margin-right: -15px;
    }
}