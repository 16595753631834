@import '../colors';

.landing-pages {
    display: flex;
    flex-direction: column;
    margin: 20px;
    border-radius: 5px;
    background-color: #E2E2E2;

    .landing-pages-top {
        display: flex;
        background-color: white;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px 5px 0px 0px;

        .landing-pages-logo {
            position: relative;
            top: -20px;
            display: flex;
            background-color: $accent-color;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 

            .landing-page-edit-logo-svg {
                width: 30px;
                height: 30px;

                path {
                  fill: white;
                }
            }
        }

        .landing-pages-title {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-weight: 300;
            margin-left: 20px;
        }
    }

    .landing-pages-grid {
        display: grid;
        margin-right: 20px;
        margin-left: -30px;
        margin-top: 40px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .add-landing-page-btn {
        min-width: 120px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border:none;
        padding: 10px 20px 10px 20px;
        outline: 0;
        margin: 20px;
        font-size: 12px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1400px) {
    .landing-pages {
        .landing-pages-grid  {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .landing-pages {
        .landing-pages-grid  {
           grid-template-columns: 1fr;
        }
    }
}