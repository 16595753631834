.create-poster {
    display: flex;
    flex-direction: column;

    .subtitle {
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin-top: 20px;
        color: #3C4858;
    }

    .description {
        font-weight: 300;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #3C4858;
        margin-right: 50px;
        font-size: 14px;
    }

    .name-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        
        input {
            margin-left: 20px;
            margin-right: 10px;
            height: 15px;
            padding: 10px;
            border: 0.75px solid #DBDBDB;
            border-radius: 25px;
            background-color: #F5F5F5;
            color: #3C4858;
            outline: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 160px;
        }

        label {
            color: #3C4858;
            font-weight: 500;
            width: 130px;
        }
    }

    .file-selector {
        margin-top: 30px;

        .file-selector-title {
            width: 130px;
        }
    }

    .color-picker-container {
        margin-top: 30px;

        .color-picker {
            .color-picker-label {
                width: 140px;
            }

            .switch-color {
                width: 160px;
            }
        }
    }

    .menu-line {
        margin-top: 30px;
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
    }

    textarea {
        border: 0.75px solid #DBDBDB;
        height: 120px;
        resize: none;
        outline: none;
        padding: 10px;
        font-size: 15px;
        background-color: #F5F5F5;
        border-radius: 6px;
        margin-top: 30px;
        font-family: Roboto Light;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        justify-content: center;

        button {
            width: 130px;
            margin: 20px;
            border: none;
            outline: none;
            border-radius: 25px;
            color: white;
            padding: 10px;
            background-color: #EA5459;
            cursor: pointer;
        }

        :nth-child(2) {
            background-color: #FFCC33;
        }
    }
}