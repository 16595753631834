
.bullet-navigator {
    display: flex;
    flex-direction: row;

    .bullet-navigator-bullet {
        margin: 2px;
        width: 15px;
        height: 15px;
    
        path {
            fill: #D8D8D8;
        }
    }

    .bullet-navigator-bullet-active {
        path {
            fill: #11BEFC;
        }
    }
}