.demographics-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    height: 100%;

    .demographics-widget-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 100%;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .demographics-widget-icon {
            height: 20px;
            align-self: flex-end;
        }

        .demographics-widget-title {
            font-weight: 500;
            font-size: 12px;
            color: #373737;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;
        }

        .demographics-widget-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
            flex: 1;

            .demographics-widge-stat {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    height: 90px;
                }

                label {
                    font-weight: 500;
                    font-size: 40px;
                    color: #FFCC33;
                    margin-left: 10px;
                }

                .female {
                    color: #EA5454;
                }
            }
        }
    }
}

.demographics-widget-edit * {
    cursor: grab;
}

.demographics-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}