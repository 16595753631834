.sms-contacts-page {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 20px;

    @media only screen and (max-width: 1240px) {
        flex-direction: column;
    }

    .file-selector-input {
        display: none;
    }

    button {
        width: 50%;
        min-width: 120px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: #EA5459;
        border:none;
        padding: 8px;
        outline: 0;
        font-size: 16px;
        cursor: pointer;
    }

    input {
        margin: 0 30px;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 21.75px;
        background-color: #ffffff;
        padding: 10px 20px 10px 20px;
        outline: 0;
        font-size: 15px;
        min-height: 30px;
    }
    
    .tags {
        flex: 1;
        height: fit-content;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        margin: 15px;

        @media only screen and (max-width: 1240px) {
            flex-direction: row;
        }

        .tags-top {
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 1240px) {
                flex: 1;
                justify-content: space-around;
                margin-bottom: 40px;
            }

            .top {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 30px;
    
                label {
                    margin-left: 10px;
                    font-size: 23.5px;
                    line-height: 28px;
                    color: #000000;
                }
            }
    
            .actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin: 30px;
        
                :nth-child(2) {
                    background-color: #FFCC33;
                    margin-left: 30px;
                }
            }
    
            hr {
                border: 1px solid #EAEAEA;
                margin-bottom: 30px;
                margin-left: 30px;
                margin-right: 30px;

                @media only screen and (max-width: 1240px) {
                    display: none;
                }
            }
        }

        .tags-bottom { 
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 1240px) {
                flex: 1;
            }

            .all-contacts {
                display: flex;
                flex-direction: column;
                padding: 10px 30px;
                cursor: pointer;

                :first-child {
                    font-size: 18px;
                    line-height: 20px;
                    color: #000000;
                    margin-bottom: 5px;
                    cursor: pointer;
                }

                :nth-child(2) {
                    font-size: 12px;
                    color: #3C4858;
                    cursor: pointer;
                }
            }

            .all-contacts-selected {
                background-color: #F4F4F4;
            }

            .tag-selector {
                overflow-y: scroll;
                height: calc(100vh - 482px);

                .tag {
                    display: flex;
                    flex-direction: row;
                    padding: 10px 30px;
                    cursor: pointer;

                    .dot {
                        width: 15px;
                        height: 15px;
                        border-radius: 25px;
                        margin-right: 20px;
                        margin-top: 10px;
                        border: 1px solid gray;
                    }

                    .name {
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;

                        :first-child {
                            font-size: 18px;
                            line-height: 20px;
                            color: #000000;
                            margin-bottom: 5px;
                            cursor: pointer;
                        }

                        :nth-child(2) {
                            font-size: 12px;
                            color: #3C4858;
                            cursor: pointer;
                        }
                    }
                }

                .tag-selected {
                    background-color: #f4f4f4;
                }
            }

            .no-tags {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }


    .contacts {
        flex: 2;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        margin: 15px;
        height: calc(100vh - 160px);

        input {
            margin: unset;
        }

        .top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 30px;

            .title {
                font-size: 23.5px;
                color: #000000;
            }

            .actions {
                display: flex;
                flex-direction: row;
                align-items: center;

                .action-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 0 10px;
                    user-select: none;

                    label {
                        margin-left: 5px;
                        font-size: 12px;
                        color: #3C4858;
                        cursor: pointer;
                    }

                    .inactive-btn {
                        path {
                            fill: #C4C4C4
                        }
                    }

                    .active-btn {
                        cursor: pointer;
                    }
                }

            }
        }

        .no-contacts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            :first-child {
                font-size: 25px;
                color: #000000;
            }

            :nth-child(2) {
                font-size: 20px;
                color: #000000;
                margin-top: 20px;
            }

            button {
                margin-top: 50px;
                width: 180px;
            }
        }

        .contacts-container {
            display: flex;
            flex-direction: column;

            .selector {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                border-radius: 25px;
                border: 1px solid #C4C4C4;
            }

            .selector-selected {
                width: 10px;
                height: 10px;
                border-radius: 25px;
                background-color: #FFCC33;
            }

            .header {
                display: grid;
                grid-template-columns: 0.2fr 1fr 1fr 1fr 0.2fr;
                border-top: 1px solid #EAEAEA;
                border-bottom: 1px solid #EAEAEA;
                padding: 20px 0px;
                padding-left: 45px;

                label {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    color: #000000;
                }
            }

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 12px;
                background: #EFEFEF;
                border-radius: 18px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 18px;
                background-color:#D9D9D9;
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }

            .sms-contact-items {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: calc(100vh - 360px);
                margin-right: 20px;
                margin-top: 20px;
                margin-bottom: 20px;

                .sms-contact-item {
                    display: grid;
                    grid-template-columns: 0.2fr 1fr 1fr 1fr 0.2fr;
                    padding: 20px 0px;
                    padding-left: 45px;    
                    .name-phone {
                        display: flex;
                        flex-direction: column;

                        :first-child {
                            font-size: 14px;
                            font-weight: bold;
                        }

                        :nth-child(2) {
                            font-size: 12px;
                        }
                    }
    
                    .contact-tags {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-left: 20px;
                        
                        .contact-tag-container {
                            padding: 3px 7px;
                            margin: 2px;
                            border-radius: 21px;
                            border: 1px solid gray;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: fit-content;  

                            label {
                                font-size: 10px;
                                display: flex;
                                color: #FFFFFF;
                            }

                            .remove-icon {
                                width: 8px;
                                height: 8px;
                                margin-left: 10px;
                                cursor: pointer;
                            }
            
                            .remove-icon-black {
                                path {
                                    fill: black;
                                }
                            }
                        }
                    }
    
                    .no-custom {
                        font-weight: 300;
                        font-size: 12px;
                        color: #000000;
                        margin-left: 10px;
                    }

                    .edit-contact-btn {
                        cursor: pointer;
                    }
                }

                .sms-contact-item:hover {
                    background-color: #F4F4F4;
                }
            }
        }
    }
}