@import '../colors';

.removable-label {
    display: flex;
    flex-direction: row;
    background-color: $accent-color;
    border-radius: 25px;
    padding: 5px 15px 5px 15px;
    margin-top: 10px;
    margin-right: 50px;
    align-items: center;
    justify-content: space-between;
    
    label {
        color: white;
    }

    .remove-icon {
        width: 10px;
        height: 10px;

        path {
            fill: white;
        }
    }
}

.edit-landing-page-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .edit-landing-page-settings-top {
        display: flex;
        flex-direction: column;

        .edit-landing-page-settings-inputs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            
            .buttoned-input {
                margin-top: 10px;

                label {
                    width: 130px;
                }
            }

            .edit-landing-page-settings-ssid {
                display: flex;
                flex-direction: column;
            }

            .edit-landing-page-settings-email {
                display: flex;
                flex-direction: column;
                margin-left: 50px;
            }

            .edit-landing-page-settings-inputs-label {
                background-color: $accent-color;
                color: white;
                border-radius: 25px;
                padding: 5px 20px 5px 20px;
                margin-top: 10px;
                margin-left: 100px;
                margin-right: 50px;
            }
        }

        .edit-landing-page-settings-switch {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .text-switch {
                margin-top: 10px;

                .text-switch-label {
                    width: 170px;
                }

                .text-switch-actions {
                    width: 60px;
                }
            }
        }
        .redirect-input {
            display: flex;
            flex-direction: row;
            user-select: none;
            align-items: center;
            margin-top: 10px;

            label {
                color: #3C4858;
                font-weight: 500;
                width: 150px;
                user-select: none;
            }

            input {
                padding: 10px 15px 10px 15px;
                border: 0.75px solid #DBDBDB;
                border-radius: 25px;
                background-color: #F5F5F5;
                color: #3C4858;
                outline: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 15px;
                flex: 1;
            }
        }
    }

    .edit-landing-page-settings-line {
        margin-top: 30px;
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
    }

    .facebook-like {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        margin-top: 30px;

        label {
            color: #3C4858;
            font-weight: 500;
            user-select: none;
        }

        .fb-likes-switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 40px;

            .switch {
                height: 25px;
                margin-left: 20px;
            }
        }

        .fb-url-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 30px;
            flex: 1;
            height: 40px;

            input {
                padding: 10px 15px 10px 15px;
                border: 0.75px solid #DBDBDB;
                border-radius: 25px;
                background-color: #F5F5F5;
                color: #3C4858;
                outline: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 15px;
                flex: 1;
                margin-left: 20px;
                margin-right: 2px;
            }
        }
    }

    .debug-switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;

        .switch {
            height: 25px;
            margin-left: 20px;
        }

        label {
            color: #3C4858;
            font-weight: 500;
            user-select: none;
            width: 178px;
        }
    }

    .edit-landing-page-settings-bottom {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1350px) {
    .edit-landing-page-settings {

        .edit-landing-page-settings-top {
            .edit-landing-page-settings-inputs {
                flex-direction: column;

                .edit-landing-page-settings-email {
                    margin-left: 0px;
                }
            }
            
            .edit-landing-page-settings-switch {
                flex-direction: column;
            }
        }

        .facebook-like { 
            flex-direction: column;
            align-items: flex-start;

            .fb-url-input {
                margin-left: 0px;
                margin-top: 10px;

                input {
                    width: 300px;
                }
            }
        }
    }
}