@import './colors'; 

.reset-password-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../images/background.svg');
    background-color: #eeeeee;
    align-items: center;
    user-select: none;

    .reset-password {
        display: flex;
        flex-direction: column;
        width: 50vw;

        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        margin-left: 50px;
        margin-top: 100px;

        .reset-password-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 20px;

            .reset-password-logo {
                position: relative;
                top: -20px;
                display: flex;
                background-color: $accent-color;
                height: 60px;
                width: 60px;
                border-radius: 0 0 0 30px;
                justify-content: center;
                align-items: center; 
                margin-left: 10px;
                margin-bottom: -10px;

                .reset-password-logo-svg {
                    width: 30px;
                    height: 30px;

                    path {
                    fill: white;
                    }
                }
            }

            label {
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 98%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 0px;
        }

        .reset-password-inputs {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-right: 100px;
            margin-top: 20px;
            margin-bottom: 40px;
            
            label {
                color: #3C4858;
                font-size: 12px;
                width: 8vw;
            }
            
            .reset-password-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
                
                .eye-icon {
                    margin-right: 10px;
                }

                .password-input {
                    display: flex;
                    flex-direction: row;
                    border: 0.75px solid #DBDBDB;
                    background-color: #F5F5F5;
                    border-radius: 21.75px;
                    box-sizing: border-box;
                    align-items: center;

                    input {
                        padding: 10px 10px 10px 10px;
                        margin-left: 10px;
                        background-color: #F5F5F5;
                        width: 20vw;
                        outline: 0;
                        border: none;
                    }
                }

            
                .input-error {
                    border: 0.75px solid $accent-color;

                }
            }

            .error-message {
                color: $accent-color;
                width: 40vw;
                margin-top: 10px;
            }
        }

        .reset-password-actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 50px;

            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
            }
        }
    }
}