.sms-campaigns-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    height: 100%;

    .sms-campaigns-widget-container { 
         display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .sms-campaigns-widget-icon {
            height: 20px;
            align-self: flex-end;
        }
    
        .sms-campaigns-widget-title {
            font-weight: 500;
            font-size: 12px;
            color: #373737;
        }

        .sms-campaigns-widget-t {
            padding: 10px 0;
        }
    
        .bordered {
            border-bottom: 1px solid #EEEEEE;
        }
    
        .sms-campaigns-widget-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;
    
            .sms-campaigns-widget-value {
                font-weight: 300;
                font-size: 38px;
                color: #3C4858;
            }

            .scw-row {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: scale-down;
                }

                .scwr-right {
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;
                }
            }
    
            .orange {
                color: #FF9933;
            }
    
            .red {
                color: #EA5454;
            }
    
            .yellow {
                color: #FFCC33;
            }

            .orange {
                color: #FF9933;
            }
        }
    }
}

.sms-campaigns-widget-edit * {
    cursor: grab;
}

.sms-campaigns-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}